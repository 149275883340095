@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.8);*/ 
    -webkit-border-radius: 5px;
    background-color:#fff;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 10px;
    background:rgb(196, 196, 196); 
}

.MuiSlider-colorInfo{
    color: black !important;
}